<template>
  <div class="form-group multi-input" :class="{ 'required': required, 'has-error': errors.length }">
    <strong>{{ titleValue }}</strong>
    <div class="additional-info" v-if="$slots['info']">
      <slot name="info"/>
    </div>
    <table class="table table-hover table-responsive">
      <thead>
      <tr>
        <th style="width: 30%;">Key</th>
        <th style="width: 70%;">Value</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(input, i) in inputs" :key="`inputs-key-${i}`">
        <td>
          <label :for="inputName(input.key)">{{ inputTitle(input.key) }}</label>
        </td>
        <td>
          <form-input v-bind="attributes" :model="input.value" :name="inputName(input.key)" :placeholder="inputTitle(input.key)"/>
        </td>
      </tr>
      </tbody>
    </table>
    <p class="help-block info" v-if="$slots['help']">
      <slot name="help"/>
    </p>
    <p class="help-block error" v-for="(error, i) in errors" :key="`${name}-error-${i}`">{{ error }}</p>
  </div>
</template>

<script>
import FormInput from '@/views/components/form/FormInput'

export default {
  name: 'FormGroupInput',
  components: {
    FormInput
  },
  props: {
    title: { type: String, default: '' },
    ...FormInput.props
  },
  computed: {
    inputs () { return this.model.value },
    errors () { return this.model.errors },
    titleValue () { return this.title ? this.title : this.$helpers.Str.titleCase(this.name) },
    attributes () {
      return {
        type: this.type,
        size: this.size || 'sm',
        readonly: this.readonly,
        required: this.required,
        disabled: this.disabled,
        pattern: this.pattern,
        auto: this.auto,
        step: this.step,
        min: this.min,
        max: this.max
      }
    }
  },
  methods: {
    inputName (key) { return this.name + '_' + key },
    inputTitle (key) { return this.$helpers.Str.titleCase(key) }
  }
}
</script>

<style lang="scss">
.form-group.multi-input {
  .table {
    thead>tr>th, thead>tr>td,
    tbody>tr>th, tbody>tr>td,
    tfoot>tr>th, tfoot>tr>td {
      padding: 5px;
    }
    th, td label {
      font-weight: normal;
    }
    td {
      vertical-align: middle;
      label {
        display: block;
        margin: 0;
      }
    }
  }
}
</style>
